import { useEffect, useMemo, useState } from 'react';

import { createFilterBuilder, formatSQLDate } from '@jebel/utils';
import { POST_REPORT_STATUS } from '@jebel/constants';

import { useSearchContext } from 'shared/features/search';
import {
  LegacyFeedMonthFilter,
  LegacyFeedPostFilter,
  LegacyTimelineMonthsQuery,
  SortOrder,
  useLegacyTimelineMonthsQuery,
} from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';

import { LegacyFilterType } from '../types';
import { useSchoolConfiguration } from 'shared/hooks';

const MONTHS_PER_PAGE = 6;

interface Options {
  year: number;
}

export function useLegacyTimelineMonths(options: Options) {
  const [refreshing, setRefreshing] = useState(false);
  const [direction, setDirection] = useState<SortOrder>('DESC');

  const { sortOption, filter: filterOptions } = useSearchContext<LegacyFilterType>();
  const { data: school } = useSchoolConfiguration();

  const filter = useMemo(() => {
    const startDate = formatSQLDate(filterOptions.legacyStartDate);
    const endDate = formatSQLDate(filterOptions.legacyEndDate);

    const filterPosts = createFilterBuilder<LegacyFeedPostFilter>()
      .and({
        id: { is_not_empty: true },
        // Ensure only allowed post are being displayed.
        // https://8base-dev.atlassian.net/browse/JEB-1580?focusedCommentId=45490
        reportStatus: { equals: POST_REPORT_STATUS.postAllowed },
      })
      .and({
        // Ensure only post that have been posted are being displayed.
        // https://github.com/8base-services/jebel/issues/1449
        OR: [{ postDate: { lte: new Date().toISOString() } }, { postDate: { is_empty: true } }],
      })
      .build();

    const filter = createFilterBuilder<LegacyFeedMonthFilter>({
      legacyYears: { legacyYear: { equals: options.year } },
      posts: {
        some: filterPosts,
      },
    });

    if (school) {
      // https://github.com/jebelapp/jebel/issues/1572
      filter.and({ school: { id: { equals: school.id } } });
    }

    if (startDate && endDate) {
      filter.and({
        posts: {
          some: {
            AND: [{ legacyDate: { gte: startDate } }, { legacyDate: { lte: endDate } }],
          },
        },
      });
    }

    return filter.build();
  }, [filterOptions, school, options.year]);

  useEffect(() => {
    const direction = sortOption.legacyDate as SortOrder;

    if (direction) {
      setDirection(direction);
    }
  }, [sortOption]);

  const {
    data: response,
    loading,
    fetchMore: next,
  } = useLegacyTimelineMonthsQuery({
    variables: {
      first: MONTHS_PER_PAGE,
      sort: [{ month: direction }],
      filter,
    },
  });

  const data = response?.months.items ?? [];
  const count = response?.months.count ?? 0;

  const hasMore = data.length < count;

  const fetchMore = async () => {
    setRefreshing(true);

    try {
      await next({
        variables: { skip: data.length },

        updateQuery(prev, { fetchMoreResult: curr }) {
          const newest = curr?.months.items ?? [];
          const oldests = prev?.months.items ?? [];

          const response: LegacyTimelineMonthsQuery = {
            ...curr,

            months: {
              count: curr?.months.count ?? count,
              items: [...oldests, ...newest],
            },
          };

          return response;
        },
      });
    } catch (err) {
      sendToSentry(err);
    } finally {
      setRefreshing(false);
    }
  };

  return {
    data,
    loading,
    refreshing,
    hasMore,

    fetchMore,
  };
}
